import { ModelCustomer } from '@/api/models/model_customer'
import CustomerDetail from '../widgets/CustomerDetail'
import Spacer from '@/components/common/util/spacer'
import { Button } from '@/@ui-lib/atoms'
import { Plus } from '@phosphor-icons/react'
import { useNavigate } from 'react-router-dom'
import { formatDate, toPascalCase } from '@/utils/utility_functions'
import { SubscriptionAPI } from '@/api/req/subscription_api'
import { useEffect, useState } from 'react'
import { FlexTable } from '@/@ui-lib/molecules'

export default function Overview({ customer }: { customer: ModelCustomer | undefined }) {
    const navigate = useNavigate()
    const onAddSubscription = () => navigate(`/customers/subscription?id=${customer!.id}`)
    const [customerSubscriptions, setCustomerSubscriptions] = useState<any>()

    const onRowClick = (row: any) => {
        navigate(
            `/subscription/details?id=${customer!.id}&subscription_id=${row.subscriptionId}`
        )
    }
    const fetchCustomerSubscriptions = (customerId: string) => {
        SubscriptionAPI.getCustomerSubscriptions(false, customerId).then((response) => {
               console.log(response);
                setCustomerSubscriptions(convertToTableProps(response))
        })
    }

    const convertToTableProps = (subscription: any) => {
        return {
            columns: [
                { key: 'planName', header: 'Plan Name' },
                { key: 'startDate', header: 'Start Date' },
                { key: 'billingPeriod', header: 'Billing Period' },
                { key: 'status', header: 'Status' },
            ],
            data: subscription.map((item:any) => {
                return {
                    subscriptionId: item.id,
                    planName: item.plan?.name ?? "",
                    status: item.status,
                    startDate: formatDate(item.start_date, 'MMMM dd, yyyy'),
                    billingPeriod: toPascalCase(item.billing_period),
                }
            }),
        }
    }

    useEffect(() => {
        if (customer) {
            fetchCustomerSubscriptions(customer.id!)
        }
    }, [])
    return (
        <div className="w-full ">
            {customer && <CustomerDetail customer={customer!} />}
            <Spacer height={20} />
            <div className="px-5 py-3 border rounded-lg bg-white border-primary shadow-md shadow-gray-300">
                <span className="text-xl font-inter font-bold text-primary">Subscriptions</span>
                <Spacer height={5} />
                <div className="flex justify-between items-center">
                    <span>Manage subscription for this customer</span>
                    <Button
                        label="Add"
                        prefixIcon={<Plus weight="bold" height={17} width={17} />}
                        type="outlined"
                        onClick={onAddSubscription}
                    />
                </div>
                <Spacer height={10} />
                {customerSubscriptions && customerSubscriptions.data.length > 0 && (
                    <FlexTable
                        onRowClick={onRowClick}
                        columns={customerSubscriptions.columns}
                        data={customerSubscriptions.data}
                    />
                )}

                {customerSubscriptions && customerSubscriptions.data.length === 0 && (
                    <div className="flex justify-center items-center mt-3">
                        <span className="w-full p-4 text-primary rounded-lg bg-primary bg-opacity-[6%]">
                            No subscriptions found
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}
