import { getClient } from '../configs/axios_configs'
import { defineCancelApiObject } from '../configs/axios_utils'

export const SubscriptionAPI = {
    getCustomerSubscriptions: async function (cancel = false, customer_id: string) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'GET',
            url: `/subscriptions?customer_id=${customer_id}&subscription_status=active`,
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.getCustomerSubscriptions.name].handleRequestCancellation().signal
                : undefined,
        })
        return response.data["subscriptions"] ?? [] ;
    },
    createSubscription: async function (cancel=false, payload:any) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'POST',
            url: `/subscriptions`,
            data:payload,
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.createSubscription.name].handleRequestCancellation().signal
                : undefined,
        })

        return response.status === 201 ? true: false;
    },
    getSubscriptionUsage: async function (cancel=false, subscription_id:string) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'POST',
            url: `/subscriptions/usage`,
            data:{subscription_id:subscription_id},
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.getSubscriptionUsage.name].handleRequestCancellation().signal
                : undefined,
        })

        return response.data;
    }
}

const cancelApiObject = defineCancelApiObject(SubscriptionAPI)
