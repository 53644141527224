import React, { useEffect, useRef, useState } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import { Plan } from '@/api/models/model_product_detail'
// import { ModelProduct } from '@/api/models/model_product'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { useNavigate } from 'react-router-dom'
import Chip from '@/components/common/chip/chip'
// import { transformTiersToV2 } from '@/utils/utility_functions'
import { CircularProgress, Container } from '@mui/material'
import FlexColors from '@/utils/colors.ts'
import '../addon/index.css'
// import PriceCellRenderer from '../addon/PriceCellRendere'
// import { ModelTableData } from '../addon/AddOnPage'
import Spacer from '@/components/common/util/spacer'
import { Button } from '@/@ui-lib/atoms'
import { Plus } from '@phosphor-icons/react'
import { EllipsisVertical } from 'lucide-react'
import { PlanAPI } from '@/api/req/plan_api'

export interface PriceTierV2 {
    from: number | string
    to: number | string
    price: number
}

function formatDate(timestamp: string) {
    const date = new Date(timestamp)
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    })
}

const PlansPage = () => {
    const gridRef = useRef<AgGridReact>(null)

    const [loading, setLoading] = useState(true)
    // const [addOns, setAddOns] = useState<ModelTableData[]>([])
    const [plans, setPlans] = useState<Plan[]>([])
    // const [products, setProducts] = useState<ModelProduct[]>()
    // const [selectedProduct, setSelectedProduct] = useState<ModelProduct | null | undefined>(null)

    // const [openPageDrawer, setOpenPageDrawer] = useState(false)

    const ActionCellRenderer: React.FC = (props: any) => {
        const navigate = useNavigate()
        return (
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <EllipsisVertical
                        className={'text-xl '} />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem
                        onClick={() => {
                            navigate(`edit?id=${props.data?.id}`, {
                                state: plans.find((plan) => plan.id == props.data?.id),
                            })
                        }}
                    >
                        Edit
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        )
    }
    useEffect(() => {
        getAllPlans()
    }, [])

    const getAllPlans = () => {
        setLoading(true)
        PlanAPI.getAllPlansNew().then((result) => {
            setPlans(result)
            setLoading(false)
        })
    }

    // const getAllPlans = () => {
    //     ProductAPI.getAll().then((result: ModelProduct[]) => {
    //         setProducts(result)
    //         setLoading(true)
    //         ProductDetailAPI.getAll(result[0].id)
    //             .then((product) => {
    //                 setPlans(product.plans)
    //                 setAddOns(convertToModelAddon(product.plans))
    //                 setSelectedProduct(result[0])
    //             })
    //             .finally(() => {
    //                 setLoading(false)
    //             })
    //     })
    // }
    // const onHandleChange = (id: string) => {
    //     setLoading(true)
    //     ProductDetailAPI.getAll(id)
    //         .then((product) => {
    //             setAddOns(convertToModelAddon(product.plans))
    //         })
    //         .finally(() => {
    //             setSelectedProduct(products?.find((product) => product?.id === id))
    //             setLoading(false)
    //         })
    // }

    // const convertToModelAddon = (plans: Plan[]): ModelTableData[] => {
    //     return plans.map((plan) => ({
    //         id: plan.id,
    //         price_id: plan.prices?.[0]?.id,
    //         name: plan.name,
    //         slug: plan.slug,
    //         tier_type: plan.prices?.[0]?.tier_type === 'VOLUME' ? 'Tiered' : 'Flat fee',
    //         status: plan.status,
    //         price:
    //             plan.prices?.[0]?.tier_type === 'VOLUME'
    //                 ? transformTiersToV2(plan.prices?.[0]?.price_tiers || [])
    //                 : plan.prices?.[0]?.monthly_price || 0,
    //         updated_at: plan.updated_at,
    //     }))
    // }

    const colDefs: ColDef[] = [
        {
            headerName: 'Name',
            field: 'name',
            filter: true,
            sortable: true,
            flex: 5,
        },
        {
            headerName: 'Status',
            field: 'status',
            cellRenderer: (params: any) => (
                <div className="flex justify-center w-full items-center h-full ">
                    <Chip isActive={params.value === 'published'} />
                </div>
            ),
            filter: true,
            sortable: true,
            width: 100,
        },
        {
            headerName: 'Last updated',
            field: 'updated_at',
            valueGetter: (s: any) => formatDate(s.data.updated_at),
            filter: true,
            sortable: true,
            flex: 2,
        },
        // {
        //     headerName: '',
        //     field: 'actions',
        //     cellRenderer: ActionCellRenderer,
        //     width: 60,
        //     pinned: 'right',
        // },
    ]

    const onGridReady = (params: any) => {
        gridRef.current!.api.sizeColumnsToFit()
        // Auto-size the Actions column to fit its content
        params.columnApi.autoSizeColumn('actions')
    }

    const navigate = useNavigate();

    return (
        <Container>
            <Spacer height={10} />
            <div className=" min-h-screen w-full">
                <div className="h-8"></div>
                <div className="w-full flex justify-between items-center">
                    <h1 className="font-semibold text-[24px] bg-white ">Plans</h1>

                    <Button label="Add Plan" prefixIcon={<Plus />} onClick={() => navigate('/plan_create')} />

                </div>
                <div className="w-full border mt-2"></div>

                <div className="ag-theme-quartz card mt-4  w-full h-auto p-4 bg-white">
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={colDefs}
                        rowData={plans}
                        domLayout="autoHeight"
                        loading={loading}
                        loadingOverlayComponent={() => (
                            <CircularProgress size={50} style={{ color: FlexColors.primary }} />
                        )}
                        onGridReady={onGridReady}
                    />
                </div>
            </div>
            {/* <PlanDrawer
                onSuccess={(data: any) => {
                    showCustomToast(data.message, ToastStatus.SUCCESS)
                    setOpenPageDrawer(false)
                    getAllPlans()
                }}
                onError={(data) => {
                    showCustomToast(data.message, ToastStatus.ERROR)
                }}
                onClose={() => setOpenPageDrawer(false)}
                drawerVisibility={openPageDrawer}
            /> */}
        </Container>
    )
}

export default PlansPage
