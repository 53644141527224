import { useState } from 'react'
import { Button, Checkbox, FlexSelect, Input } from '@/@ui-lib/atoms'
import { extractFiltersFromMeter } from '@/utils/utility_functions'
import { Tier } from './TieredRow'
import TierTable from './TieredRow'

interface PriceCreationProps {
    meters: any
    onSubmit: (data: any) => void
    billing_monthly: boolean
    billing_annually: boolean
}


export interface PricingForm {
    pricing_type: 'usage' | 'recurring';
    billable_metric?: string;
    filter: string[];
    monthly_price?: number;
    annual_price?: number;
    monthly_price_units: number;
    annual_price_units: number;
    default_monthly_price?: number;
    default_annual_price?: number;
    default_monthly_price_units: number;
    default_annual_price_units: number;
    billing_model?: 'PACKAGE' | 'FLAT_FEE' | 'TIERED';
    default_monthly_price_tiered: Tier[];
    default_annual_price_tiered: Tier[];
    monthly_price_tiered: Tier[];
    annual_price_tiered: Tier[];
}


const defaultPriceCreationForm = (): PricingForm => ({
    pricing_type: 'usage',
    billable_metric: undefined,
    filter: [],
    monthly_price: undefined,
    annual_price: undefined,
    monthly_price_units: 0,
    annual_price_units: 0,
    default_monthly_price: undefined,
    default_annual_price: undefined,
    default_monthly_price_units: 0,
    default_annual_price_units: 0,
    billing_model: 'FLAT_FEE',
    default_monthly_price_tiered: [
        {
            from: 0,
            to: '&above',
            unit: 0,
            price: 0,
        },
    ],
    default_annual_price_tiered: [
        {
            from: 0,
            to: '&above',
            unit: 0,
            price: 0,
        },
    ],
    monthly_price_tiered: [
        {
            from: 0,
            to: '&above',
            unit: 0,
            price: 0,
        },
    ],
    annual_price_tiered: [
        {
            from: 0,
            to: '&above',
            unit: 0,
            price: 0,
        },
    ],
});


export default function PriceCreation({ meters, onSubmit, billing_monthly, billing_annually }: PriceCreationProps) {
    const [priceCreationForm, setPriceCreationForm] = useState(defaultPriceCreationForm)


    // Handle form state changes
    const handleChange = (key: string, value: any) => {
        setPriceCreationForm((prevState) => ({
            ...prevState,
            [key]: value,
        }))
    }

    const addTier = (key: keyof typeof priceCreationForm) => {
        setPriceCreationForm((prevState) => {
            const tieredPricing = prevState[key] as Tier[];
            const lastTier = tieredPricing[tieredPricing.length - 1];

            let newFrom: number;

            if (lastTier.to === '&above') {
                lastTier.to = (lastTier.from as number) + 1;
                newFrom = lastTier.to + 1;
            } else {
                newFrom = (lastTier.to as number) + 1;
            }

            return {
                ...prevState,
                [key]: [
                    ...tieredPricing,
                    {
                        from: newFrom,
                        to: '&above',
                        unit: 0,
                        price: 0,
                    },
                ],
            };
        });
    };


    const removeTier = (key: keyof typeof priceCreationForm, index: number) => {
        setPriceCreationForm((prevState) => ({
            ...prevState,
            [key]: (prevState[key] as Tier[]).filter((_, i) => i !== index),
        }));
    };


    const handleTierChange = (
        key: keyof typeof priceCreationForm,
        index: number,
        field: keyof Tier,
        value: number | string
    ) => {
        setPriceCreationForm((prevState) => ({
            ...prevState,
            [key]: (prevState[key] as Tier[]).map((tier, i) => {
                if (i === index) {
                    return { ...tier, [field]: value };
                }
                if (i === index + 1 && field === 'to') {
                    return { ...tier, from: Number(value) + 1 };
                }
                return tier;
            }),
        }));
    };


    return (
        <div>
            <div className="flex items-center gap-4">
                <Checkbox
                    label="Recurring"
                    selected={priceCreationForm.pricing_type === 'recurring'}
                    onClick={() =>
                        handleChange('pricing_type', priceCreationForm.pricing_type === 'recurring' ? '' : 'recurring')
                    }
                />
                <Checkbox
                    label="Usage"
                    selected={priceCreationForm.pricing_type === 'usage'}
                    onClick={() =>
                        handleChange('pricing_type', priceCreationForm.pricing_type === 'usage' ? '' : 'usage')
                    }
                />
            </div>
            <div className="mt-5">
                {priceCreationForm.pricing_type === "usage" && <FlexSelect
                    label="Billable metric"
                    name="billable_metric"
                    field={{
                        value: priceCreationForm.billable_metric,
                        onChange: (value: any) => {
                            handleChange('billable_metric', value)
                            console.log(value)
                        },
                    }}
                    options={Object.keys(meters ?? {}).map((key) => ({
                        value: meters[key].name ?? '',
                        id: meters[key].id ?? '',
                    }))}
                />}
                <div className='mt-3    ' />
                <FlexSelect
                    label="Billing model"
                    name="billing_model"
                    field={{
                        value: priceCreationForm.billing_model,
                        onChange: (value: string) => {
                            handleChange('billing_model', value)
                        },
                    }}
                    options={[
                        { label: 'Package Pricing', key: 'PACKAGE' },
                        { label: 'Flat fee', key: 'FLAT_FEE' },
                        { label: 'Tiered Pricing', key: 'TIERED' },
                    ].map((key) => ({
                        value: key.label,
                        id: key.key,
                    }))}
                />
            </div>
            {priceCreationForm.billable_metric && priceCreationForm.billing_model && priceCreationForm.pricing_type === "usage" && <div className='mt-2 text-primary font-semibold'>Default Pricing</div>}
            <div className="mt-2">
                {priceCreationForm.pricing_type === "usage" && billing_monthly && <div className="mt-2">
                    {priceCreationForm.billing_model === 'PACKAGE' && (
                        <div>
                            <span>Monthly price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.default_monthly_price,
                                        onChange: (e: any) => handleChange('default_monthly_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="default_monthly_price"
                                />
                                {priceCreationForm.pricing_type === "usage" && <div className='flex items-center gap-2'>
                                    <span>per</span>
                                    <Input
                                        label=""
                                        field={{
                                            value: priceCreationForm.default_monthly_price_units,
                                            onChange: (e: any) => handleChange('default_monthly_price_units', e.target.value),
                                        }}
                                        placeholder="0"
                                        suffix="/unit/month"
                                        name="default_monthly_price"
                                    /></div>}
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'FLAT_FEE' && (
                        <div>
                            <span>Monthly price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.default_monthly_price,
                                        onChange: (e: any) => handleChange('default_monthly_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="default_monthly_price"
                                />
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'TIERED' && (
                        <TierTable
                            billing_period={billing_monthly ? "Monthly" : "Annually"}
                            tiers={priceCreationForm.default_monthly_price_tiered}
                            addTier={() => addTier('default_monthly_price_tiered')}
                            removeTier={(i) => removeTier('default_monthly_price_tiered', i)}
                            handleTierChange={(index, field, value) => handleTierChange(`default_monthly_price_tiered`, index, field, value)}
                        />

                    )}
                </div>}
                {priceCreationForm.pricing_type === "usage" && billing_annually && <div className="mt-2">

                    {priceCreationForm.billing_model === 'PACKAGE' && (
                        <div>
                            <span>Annual price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.default_annual_price,
                                        onChange: (e: any) => handleChange('default_annual_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="annual_price"
                                />
                                {priceCreationForm.pricing_type === "usage" && <div className='flex items-center gap-2'>
                                    <span>per</span>
                                    <Input
                                        label=""
                                        field={{
                                            value: priceCreationForm.default_annual_price_units,
                                            onChange: (e: any) => handleChange('default_annual_price_units', e.target.value),
                                        }}
                                        placeholder="0"
                                        suffix="/unit/month"
                                        name="annual_price"
                                    /> </div>}
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'FLAT_FEE' && (
                        <div>
                            <span>Annual price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.default_monthly_price,
                                        onChange: (e: any) => handleChange('default_annual_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="default_annual_price"
                                />
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'TIERED' && (
                        <TierTable
                            billing_period={billing_monthly ? "Monthly" : "Annually"}
                            tiers={priceCreationForm.default_annual_price_tiered}
                            addTier={() => addTier('default_annual_price_tiered')}
                            removeTier={(i) => removeTier('default_annual_price_tiered', i)}
                            handleTierChange={(index, field, value) => handleTierChange(`default_annual_price_tiered`, index, field, value)}
                        />

                    )}
                </div>}
                {priceCreationForm.billable_metric && priceCreationForm.pricing_type === "usage" && (
                    <div>
                        <h3 className='mt-2 text-primary font-semibold'>Filters</h3>
                        {priceCreationForm.filter.length > 0 && (
                            <div>
                                <div className="flex flex-wrap gap-2 p-2">
                                    {priceCreationForm.filter.map((filterItem: string, index: number) => (
                                        <div
                                            key={index}
                                            className="flex items-center px-3 py-1 bg-gray-200 rounded-full text-sm"
                                        >
                                            <span>{filterItem}</span>
                                            <button
                                                onClick={() => {
                                                    const temp_filter = priceCreationForm.filter.filter((item) => item !== filterItem);
                                                    handleChange('filter', temp_filter);
                                                }}
                                                className="ml-2 text-blue-500 hover:text-blue-700"
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <FlexSelect
                            label=""
                            name="filter"
                            field={{
                                onChange: (value: string) => {
                                    if (!priceCreationForm.filter.includes(value)) {
                                        const temp_filter = [...priceCreationForm.filter, value]
                                        handleChange('filter', temp_filter)
                                    }
                                },
                            }}
                            options={extractFiltersFromMeter(meters[priceCreationForm.billable_metric]).map(
                                (key) => ({
                                    value: key,
                                    id: key,
                                })
                            )}
                        />
                    </div>
                )}
                {(priceCreationForm.pricing_type === 'usage' && priceCreationForm.filter.length > 0) && billing_monthly && <div className="mt-2">

                    {priceCreationForm.billing_model === 'PACKAGE' && (
                        <div>
                            <span>Monthly price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.monthly_price,
                                        onChange: (e: any) => handleChange('monthly_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="monthly_price"
                                />
                                {priceCreationForm.pricing_type === "usage" && <div className='flex items-center gap-2'><span>per</span>
                                    <Input
                                        label=""
                                        field={{
                                            value: priceCreationForm.monthly_price_units,
                                            onChange: (e: any) => handleChange('monthly_price_units', e.target.value),
                                        }}
                                        placeholder="0"
                                        suffix="/unit/month"
                                        name="monthly_price"
                                    /></div>}
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'FLAT_FEE' && (
                        <div>
                            <span>Monthly price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.monthly_price,
                                        onChange: (e: any) => handleChange('monthly_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="monthly_price"
                                />
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'TIERED' && (
                        <TierTable
                            billing_period={billing_monthly ? "Monthly" : "Annually"}

                            tiers={priceCreationForm.monthly_price_tiered}
                            addTier={() => addTier('monthly_price_tiered')}
                            removeTier={(i) => removeTier('monthly_price_tiered', i)}
                            handleTierChange={(index, field, value) => handleTierChange(`monthly_price_tiered`, index, field, value)}
                        />

                    )}
                </div>}
                {(priceCreationForm.pricing_type === 'usage' && priceCreationForm.filter.length > 0) && billing_annually && <div className="mt-2">

                    {priceCreationForm.billing_model === 'PACKAGE' && (
                        <div>
                            <span>Annual price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.annual_price,
                                        onChange: (e: any) => handleChange('annual_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="monthly_price"
                                />
                                {priceCreationForm.pricing_type === "usage" && <div className='flex items-center gap-2'><span>per</span>
                                    <Input
                                        label=""
                                        field={{
                                            value: priceCreationForm.annual_price_units,
                                            onChange: (e: any) => handleChange('annual_price_units', e.target.value),
                                        }}
                                        placeholder="0"
                                        suffix="/unit/month"
                                        name="monthly_price"
                                    /></div>}
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'FLAT_FEE' && (
                        <div>
                            <span>Annual price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.default_monthly_price,
                                        onChange: (e: any) => handleChange('annual_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="annual_price"
                                />
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'TIERED' && (
                        <TierTable
                            billing_period={billing_monthly ? "Monthly" : "Annually"}

                            tiers={priceCreationForm.annual_price_tiered}
                            addTier={() => addTier('annual_price_tiered')}
                            removeTier={(i) => removeTier('annual_price_tiered', i)}
                            handleTierChange={(index, field, value) => handleTierChange(`annual_price_tiered`, index, field, value)}
                        />

                    )}
                </div>}
                {priceCreationForm.pricing_type === 'recurring' && billing_annually && <div className="mt-2">

                    {priceCreationForm.billing_model === 'PACKAGE' && (
                        <div>
                            <span>Annual price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.annual_price,
                                        onChange: (e: any) => handleChange('annual_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="monthly_price"
                                />
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'FLAT_FEE' && (
                        <div>
                            <span>Annual price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.default_monthly_price,
                                        onChange: (e: any) => handleChange('annual_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="annual_price"
                                />
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'TIERED' && (
                        <TierTable
                            billing_period={billing_monthly ? "Monthly" : "Annually"}

                            tiers={priceCreationForm.annual_price_tiered}
                            addTier={() => addTier('annual_price_tiered')}
                            removeTier={(i) => removeTier('annual_price_tiered', i)}
                            handleTierChange={(index, field, value) => handleTierChange(`annual_price_tiered`, index, field, value)}
                        />

                    )}
                </div>}
                {priceCreationForm.pricing_type === 'recurring' && billing_monthly && <div className="mt-2">

                    {priceCreationForm.billing_model === 'PACKAGE' && (
                        <div>
                            <span>Monthly price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.monthly_price,
                                        onChange: (e: any) => handleChange('monthly_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="monthly_price"
                                />
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'FLAT_FEE' && (
                        <div>
                            <span>Monthly price</span>

                            <div className="flex gap-2 items-center">
                                <Input
                                    label=""
                                    field={{
                                        value: priceCreationForm.monthly_price,
                                        onChange: (e: any) => handleChange('monthly_price', e.target.value),
                                    }}
                                    placeholder="$0.00"
                                    name="monthly_price"
                                />
                            </div>
                        </div>
                    )}
                    {priceCreationForm.billing_model === 'TIERED' && (
                        <TierTable
                            billing_period={billing_monthly ? "Monthly" : "Annually"}

                            tiers={priceCreationForm.monthly_price_tiered}
                            addTier={() => addTier('monthly_price_tiered')}
                            removeTier={(i) => removeTier('monthly_price_tiered', i)}
                            handleTierChange={(index, field, value) => handleTierChange(`monthly_price_tiered`, index, field, value)}
                        />

                    )}
                </div>}
            </div>
            <div className='mt-2'>
                <Button
                    label="+ Add"
                    onClick={() => {
                        onSubmit(priceCreationForm)
                        setPriceCreationForm(defaultPriceCreationForm)
                    }}
                />
            </div>
        </div>
    )
}
