import { getCloudClient } from '../configs/axios_cloud_config'
import { defineCancelApiObject } from '../configs/axios_utils'

export const MeterAPI = {
    createMeter: async function (cancel = false, payload: any) {
        const client = await getCloudClient()
        const response = await client!.request({
            method: 'POST',
            url: '/meters',
            data: payload,
            signal: cancel ? cancelApiObject[this.createMeter.name].handleRequestCancellation().signal : undefined,
        })
        const result = response.status === 201
        return result
    },
    getAll: async function (cancel = false) {
        const client = await getCloudClient()
        const response = await client!.get('/meters', {
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })
        return response.data ?? []
    },
    deleteMeter: async function (cancel = false, id: string) {
        const client = await getCloudClient()
        const response = await client!.post(`/meters/${id}/disable`, {
            signal: cancel ? cancelApiObject[this.deleteMeter.name].handleRequestCancellation().signal : undefined,
        })
        return response.status === 200
    },
    getMeterUsage: async function (cancel = false, payload: any) {
        const client = await getCloudClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'POST',
            url: `/events/usage/meter`,
            data:payload,
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            signal: cancel
                ? cancelApiObject[this.getMeterUsage.name].handleRequestCancellation().signal
                : undefined,
        })
        return response.data ?? {}
    },
}

const cancelApiObject = defineCancelApiObject(MeterAPI)
