import { ModelCustomer } from '@/api/models/model_customer'
import { Drawer } from 'antd'
import { FormBuilder } from '@/@ui-lib/molecules'
import { MeterAPI } from '@/api/req/meter_api'
import React, { useState } from 'react'
import { FieldConfig } from '@/@ui-lib/molecules/FormBuilder/FormBuilder'
import MeterGroup, { Group } from '@/components/meter/meter_group'

interface MeterDrawerProps {
    onSuccess: (value: any) => void
    onError: (value: any) => void
    onClose: () => void
    customer?: ModelCustomer
    drawerVisibility: boolean
}

export default function MeterDrawer({ onSuccess, onError, onClose, customer, drawerVisibility }: MeterDrawerProps) {
    const [meterGroups, setMeterGroups] = useState<Group[]>([])

    const formFields: FieldConfig[] = [
        {
            name: 'event_name',
            label: 'Event Name',
            type: 'text',
            placeholder: 'tokens_total',
            validation: {
                required: 'event name required',
            },
            description:
                'A unique identifier for the meter. This is used to reference the meter in the Flexprice APIs.',
        },
        {
            name: 'name',
            label: 'Display Name',
            type: 'text',
            placeholder: 'Total Tokens',
            description: 'This will be shown to your customers',
        },
        {
            name: 'aggregation',
            label: 'Aggregation',
            type: 'select',
            placeholder: 'SUM',
            validation: {
                required: 'Aggregation must be selected',
            },
            description: 'The aggregation function to apply to the event values.',

            options: [
                { id: 'SUM', value: 'Sum' },
                { id: 'COUNT', value: 'Count' },
            ],
        },
        {
            name: 'aggregation_field',
            label: 'Aggregation Value',
            type: 'text',
            placeholder: 'tokens',
            description: 'Name of the property in data object holding the value to aggregate over. ',
        },
        {
            name: 'reset_usage',
            label: 'Aggregation Rule',
            type: 'select',
            placeholder: '',
            validation: {
                required: 'Meter Type must be selected',
            },
            description: 'Defines how to reset the usage',

            options: [
                { id: 'RESET_PERIOD', value: 'Periodic reset' },
                { id: 'NEVER', value: 'Cumulative' },
            ],
        },
    ]

    const formRef = React.useRef<any>(null)

    const submitForm = async (data: Record<string, any>) => {
        const payload = {
            filters: meterGroups,
            aggregation: {
                field: data.aggregation_field,
                type: data.aggregation,
            },
            event_name: data.event_name,
            name: data.name,
            reset_usage: data.reset_usage,
        }
        await MeterAPI.createMeter(false, payload).then((result) => {
            if (result) {
                onSuccess({ message: 'Meter created successfully' })
                formRef.current?.reset()
                setMeterGroups([])
            } else {
                onError({ message: 'Failed to create plan' })
            }
        })
    }
    return (
        <Drawer title={customer ? 'Edit Meter' : 'Add Meter'} onClose={onClose} open={drawerVisibility}>
            <FormBuilder fields={formFields} onSubmit={submitForm} ref={formRef}>
                <MeterGroup
                    meterGroups={meterGroups}
                    setMetermeterGroups={setMeterGroups}
                    onDataChange={(groups) => {
                        setMeterGroups(groups)
                    }}
                />
            </FormBuilder>
        </Drawer>
    )
}
