import { forwardRef } from 'react'

interface InputProps {
    name: string
    label: string
    placeholder: string
    field: any
    errors?: any
    disabled?: boolean
    required?: boolean
    description?: string
    suffix?: string
    type?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        { label, name, placeholder, field, disabled = false, errors = {}, required = false, suffix, type = 'text', description },
        ref
    ) => {
        const errorMessage = errors[name]?.message
        return (
            <div className="mb-4 font-inter">
                <label htmlFor={label} className="block text-base  text-[#1E1E1E] mb-1">
                    {label}
                    {required && <span className="text-[#1E1E1E] ml-1">*</span>}
                </label>
                <div className="relative">
                    <input
                        {...field}
                        id={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        type={type}
                        ref={ref}
                        className={`block w-full px-3 py-2 border ${
                            errorMessage ? 'border-primary text-primary' : 'border-gray-300'
                        } rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm ${
                            disabled ? 'bg-gray-100 cursor-not-allowed' : ''
                        } ${suffix ? 'pr-10' : ''}`}
                    />
                    {suffix && (
                        <span className="absolute inset-y-0 right-3 flex items-center text-gray-500 text-sm">
                            {suffix}
                        </span>
                    )}
                </div>
                {errorMessage && <span className="text-primary text-sm mt-1">{errorMessage}</span>}
                {description && <div className="text-[#8A8A8A] text-[12px] mt-[6px]">{description}</div>}
            </div>
        )
    }
)

Input.displayName = 'Input'

export default Input
