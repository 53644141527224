import { useLocation, useNavigate } from 'react-router-dom'
import supabase from '../../../utils/supabase'
import { ConfigProvider, Menu, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useUser } from '@/context/UserContext'
import UserProfile from '../user_profile/user_profile'
import { Divider } from '@mui/material'
import { LuUsers } from 'react-icons/lu'
import { RiDoubleQuotesR, RiSpeedUpLine, RiUploadCloud2Line } from 'react-icons/ri'
import { SiGraphql, SiSimpleanalytics, SiVitest } from 'react-icons/si'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { IoDocument } from 'react-icons/io5'
import { IoIosLogOut } from 'react-icons/io'
import { GrCatalogOption, GrGraphQl, GrTest } from 'react-icons/gr'
import { MdOutlineInsights, MdQueryBuilder } from 'react-icons/md'
import { HiOutlineExternalLink } from 'react-icons/hi'
interface MenuItem {
    key: string
    label: string | React.ReactNode
    icon?: React.ReactNode
    roles?: string[]
    onClick?: () => void
    children?: MenuItem[]
    disabled?: boolean
    highlighticon?: string
    unhighlighticon?: string
}

const SideNavBar: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useUser()
    const [selectedKey, setSelectedKey] = useState(location.pathname)
    const [collapsed, setCollapsed] = useState(false)

    useEffect(() => {
        setSelectedKey(location.pathname)
    }, [location.pathname])

    const handleNavigate = (key: string) => {
        setSelectedKey(key)
        navigate(key)
    }

    const menuItems = [
        // {
        //     // icon: <DollarSign className={'text-xl size-6'} />,
        //     icon: <GrCatalogOption className={'text-xl size-6'} />,
        //     key: '/price',
        //     label: 'Pricing Page',
        //     roles: ['sales', 'admin'],
        //     onClick: () => handleNavigate('/price'),
        //     highlighticon: '/assets/png/plan-highlighted.png',
        //     unhighlighticon: '/assets/png/plan-unhighlighted.png',
        // },
        {
            icon: <GrCatalogOption className={'text-xl size-6'} />,
            key: '/plans',
            label: 'Plans',
            roles: ['sales', 'admin'],
            onClick: () => navigate('/plans'),
        },
        {
            icon: <RiSpeedUpLine className={'text-xl size-6'} />,
            key: '/metering',
            label: 'Metering',
            roles: ['sales', 'admin'],
            onClick: () => handleNavigate('/metering'),
        },
        {
            key:'/query',
            label: 'Query',
            roles: ['sales', 'admin'],
            icon: <SiGraphql className={'text-xl size-6'} />,
            onClick: () => handleNavigate('/query'),
        },
        {
            key: '/customers',
            label: 'Customers',
            roles: ['admin'],
            icon: <LuUsers className={'text-xl size-6'} />,
            onClick: () => navigate('/customers'),
        },
        // {
        //     key: '/addon',
        //     label: 'Add-ons',
        //     roles: ['sales', 'admin'],
        //     icon: <FilePlus className={'text-xl size-6'} />,
        //     onClick: () => handleNavigate('/addon'),
        // },
        // {
        //     key: '/quotes',
        //     label: 'Quotation',
        //     roles: ['sales', 'admin'],
        //     icon: <RiDoubleQuotesR className={'text-xl size-6'} />,
        //     onClick: () => handleNavigate('/quotes'),
        //     highlighticon: '/assets/png/quote-highlighted.png',
        //     unhighlighticon: '/assets/png/quote.png',
        // },

        // {
        //     key: '/import-export',
        //     label: 'Import-Export',
        //     roles: ['admin'],
        //     icon: <RiUploadCloud2Line className={'text-xl size-6'} />,
        //     onClick: () => handleNavigate('/import-export'),
        // },
        {
            key: '1',
            label: (
                <span>
                    Analytics <span className="italic text-[0.65rem] text-opacity-15 opacity-50"></span>
                </span>
            ),
            roles: ['sales', 'admin'],
            icon: <SiSimpleanalytics className={'text-lg ml-1 size-4 mr-1'} />,
            disabled: true,
        },
        {
            key: '3',
            label: (
                <span>
                    Experiments
                    <span className="italic text-[0.65rem] text-opacity-15 opacity-50"></span>
                </span>
            ),
            roles: ['sales', 'admin'],
            icon: <GrTest className={'text-xl size-6'} />,
            disabled: true,
        },
        {
            key: '5',
            label: (
                <span>
                    Integrations
                    <span className="italic text-[0.65rem] text-opacity-15 opacity-50"></span>
                </span>
            ),
            roles: ['sales', 'admin'],
            icon: <SiVitest className={'text-xl size-6'} />,
            disabled: true,
        },
        {
            key: '6',
            label: (
                <span>
                    AI Insights
                    <span className="italic text-[0.65rem] text-opacity-15 opacity-50"></span>
                </span>
            ),
            roles: ['sales', 'admin'],
            icon: <MdOutlineInsights className={'text-xl size-6'} />,
            disabled: true,
        },
    ]
    const renderMenuItems = (items: MenuItem[]): MenuItem[] =>
        items.map((item) => ({
            key: item.key,
            label: collapsed ? null : <span className="font-semibold select-none">{item.label}</span>,
            icon: item.icon,
            disabled: item.disabled,
            onClick: item.onClick,
            children: item.children && !collapsed ? renderMenuItems(item.children) : undefined,
        }))

    return (
        <aside id="sidebar" className="z-50 h-screen hidden md:block shadow-md">
            <nav className="h-screen max-w-64 flex flex-col border-r transition-all duration-300 ease-in-out">
                <div
                    className={`w-full max-h-[4rem] h-[4rem] p-2 flex ${
                        collapsed ? 'justify-center' : 'justify-between'
                    } items-center`}
                >
                    <img
                        src="/assets/logo/logo.png"
                        alt="FlexPrice Logo"
                        className={`h-6 ml-5 bg-contain ${collapsed ? 'hidden' : ''}`}
                    />
                    <button className="text-xl p-2" onClick={() => setCollapsed((prev) => !prev)}>
                        {collapsed ? (
                            <FaAngleRight className="text-gray-400" />
                        ) : (
                            <FaAngleLeft className="text-gray-400" />
                        )}
                    </button>
                </div>

                <ul
                    className={`flex-1 overflow-y-auto mt-3 transition-all duration-300 ${collapsed ? 'w-20' : 'w-64'}`}
                >
                    <ConfigProvider
                        theme={{
                            components: { Menu: {} },
                            token: { colorPrimary: '#2257BE', colorText: '#8A8A8A' },
                        }}
                    >
                        <Menu
                            selectedKeys={[selectedKey]}
                            mode="inline"
                            items={renderMenuItems(
                                menuItems.filter((item) => !item.roles || item.roles.includes(user.role))
                            )}
                            style={{ maxWidth: '16rem' }}
                        />
                    </ConfigProvider>
                </ul>

                <div className={'p-2 '}>
                    <a
                        href="https://documenter.getpostman.com/view/35977379/2sAY4xBNAS"
                        target="_blank"
                        rel="noreferrer"
                        className={
                            'w-full p-2 px-4 font-inter gap-2 text-gray-500 rounded-md hover:bg-gray-200 flex items-center active:scale-95 transition-all duration-300 font-medium'
                        }
                    >
                        {collapsed ? (
                            <Tooltip placement="right" title={'Documentation'}>
                                <IoDocument className={'text-[14px]'} />
                            </Tooltip>
                        ) : (
                            <>
                                <IoDocument className={'text-[14px]'} />
                                <p className="ml-0 text-[#8A8A8A] text-[14px]">Documentation</p>
                                <span className="ml-auto">
                                    <HiOutlineExternalLink className={'text-2xl'} />
                                </span>
                            </>
                        )}
                    </a>

                    <button
                        onClick={() => {
                            supabase.auth.signOut()
                            handleNavigate('/login')
                        }}
                        className={
                            'w-full p-2 px-4 font-inter gap-2 text-gray-500 rounded-md hover:bg-gray-200 flex items-center active:scale-95 transition-all duration-300 font-medium'
                        }
                    >
                        {collapsed ? (
                            <Tooltip placement="right" title={'Logout'}>
                                <IoIosLogOut className={'text-[14px]'} />
                            </Tooltip>
                        ) : (
                            <IoIosLogOut className={'text-[14px]'} />
                        )}
                        {collapsed ? '' : <span className="ml-0 text-[14px] text-[#8A8A8A]">Logout</span>}
                    </button>
                    <div className="h-2"></div>
                    <Divider />

                    <div
                        className={
                            'w-full max-w-full p-2 px-4 font-inter gap-2 mt-2 text-gray-500 rounded-md    flex items-center select-none font-medium'
                        }
                    >
                        {collapsed ? (
                            <Tooltip placement="right" title={'Logout'}>
                                <UserProfile />
                            </Tooltip>
                        ) : (
                            <div className="w-full flex justify-between items-center gap-2">
                                <span className="w-full flex gap-2 items-center justify-start">
                                    <UserProfile />
                                    <div className="ml-0 text-[#8A8A8A]">
                                        <p className="text-clip text-wrap text-[12px]">{user.user_name}</p>
                                        {/* <p className="text-clip text-wrap text-[12px]">{user.email}</p> */}
                                    </div>
                                </span>
                                {/* <button>
                                    <FaChevronUp />
                                </button> */}
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </aside>
    )
}

export default SideNavBar
