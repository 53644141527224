import { getCloudClient } from '../configs/axios_cloud_config'
import { getClient } from '../configs/axios_configs'
import { defineCancelApiObject } from '../configs/axios_utils'

export const PlanAPI = {
    update: async function (cancel = false, priceId: string, payload: any) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'PUT',
            url: `/gw/plans/${priceId}`,
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            data: payload,
            signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
        })
        const result: boolean = response.status === 200 ? true : false
        return result
    },
    create: async function (cancel = false, payload: any) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'POST',
            url: `/gw/prices/create`,
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            data: payload,
            signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        })
        const result: boolean = response.status === 201 ? true : false
        return result
    },
    createPlan: async function (cancel = false, payload: any) {
        const client = await getClient()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'POST',
            url: `/gw/plans/create`,
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            data: payload,
            signal: cancel ? cancelApiObject[this.createPlan.name].handleRequestCancellation().signal : undefined,
        })
        const result: boolean = response.status === 201 ? true : false
        return result
    },
    createPlanNew: async function (cancel=false,payload:any){
        const client = await getCloudClient()
        const envId = localStorage.getItem('env_id')
        const response = await client?.request({
            method: 'POST',
            url: `plans`,
            headers:{
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            data: payload,
            signal: cancel ? cancelApiObject[this.createPlanNew.name].handleRequestCancellation().signal : undefined,

        })
        const result: boolean = response!.status === 201 ? true : false
        return result
    },
    getAllPlansNew: async function(cancel=false){
        const client = await getCloudClient()
        const envId = localStorage.getItem('env_id')
        const response = await client?.request({
            method: 'GET',
            url: `/plans`,
            headers:{
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            signal: cancel? cancelApiObject[this.getAllPlansNew.name].handleRequestCancellation().signal : undefined,
        })
        return response!.data["plans"]?? []
    }
}

const cancelApiObject = defineCancelApiObject(PlanAPI)
