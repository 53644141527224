import { getCloudClientLocal } from '../configs/axios_config_old'
import { defineCancelApiObject } from '../configs/axios_utils'
import { ModelUser } from '../models/model_user'

export const UserAPI = {
    me: async function (cancel = false, payload: any) {
        const client = await getCloudClientLocal()
        const envId = localStorage.getItem('env_id')
        const response = await client!.request({
            method: 'GET',
            url: '/gw/user/me',
            headers: {
                'X-Environment-ID': envId,
                'Content-Type': 'application/json',
            },
            data: payload,
            signal: cancel ? cancelApiObject[this.me.name].handleRequestCancellation().signal : undefined,
        })
        const result: ModelUser = response.data.data
        return result
    },
}

const cancelApiObject = defineCancelApiObject(UserAPI)
