import { useEffect, useState } from 'react'
import { FlexSelect, Loader } from '@/@ui-lib/atoms'
import { MeterAPI } from '@/api/req/meter_api'
import { ModelMeter } from '@/api/models/model_meter'
import BarChart from '@/@ui-lib/molecules/Chart'
import { Container } from '@mui/material'

interface UsageObject {
    window_size?: string
    meter_id?: string
}

const QueryMeterPage = () => {
    const [loading, setLoading] = useState(true)
    const [meters, setMeters] = useState<ModelMeter[]>([])
    const [selectedEvent, setSelectedEvent] = useState<string | undefined>()
    const [usage, setUsage] = useState<any>({})
    const [apiPayload, setApiPayload] = useState<UsageObject>({})
    const [window, setWindow] = useState<string>('DAY')

    useEffect(() => {
        getAllMeters()
    }, [])

    const getAllMeters = async () => {
        setLoading(true)
        MeterAPI.getAll(false).then((value)=>{
            setMeters(value)
            setApiPayload({meter_id: value[0].id, window_size:window})
            getEventUsage({meter_id: value[0].id, window_size:window})
            setSelectedEvent(value[0].id)
        })
    }

    const getEventUsage = async (payload: UsageObject) => {
        setLoading(true)
        MeterAPI.getMeterUsage(false, payload).then((response)=>{
            setUsage(response)
            setLoading(false)
        })
    }

    return (
        <div className="min-h-screen w-full bg-white">
            <Container>

                <div className="h-8"></div>
                <div className="w-full flex justify-between items-center">
                    <h1 className="font-semibold text-[24px] bg-white ">Query</h1>
                </div>
                <div className="w-full border mt-2"></div>
                <div className="flex items-center gap-5 p-5">

                    <FlexSelect
                        label="Select event"
                        name="event"
                        field={{
                            onChange: (value: string) => {
                                setSelectedEvent(value)
                                const updatedPayload = { ...apiPayload, meter_id: value }
                                setApiPayload(updatedPayload)
                                getEventUsage(updatedPayload)
                            },
                            value: selectedEvent,
                        }}
                        options={meters?.map((meter) => ({
                            value: meter.name!,
                            id: meter.id!,
                        }))}
                    />
                    <FlexSelect
                        label="Window size"
                        name="window_size"
                        field={{
                            onChange: (value: string) => {
                                setWindow(value)
                                const updatedPayload = { ...apiPayload, window_size: value.toUpperCase() }
                                setApiPayload(updatedPayload)
                                getEventUsage(updatedPayload)
                            },
                            value: window,
                        }}
                        options={['Minute', 'Hour', 'Day'].map((win) => ({
                            value: win,
                            id: win.toUpperCase(),
                        }))}
                    />
                </div>
                <div>
                    {loading && <Loader />}
                    {!loading && usage.results && <BarChart {...usage} />}
                </div>
            </Container>

        </div>
    )
}

export default QueryMeterPage
