import { forwardRef } from 'react'

interface CheckboxProps {
    label: string
    selected?: boolean
    onClick?: () => void
}

const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(({ label, selected = false, onClick, ...props }, ref) => {
    return (
        <button
            ref={ref}
            onClick={onClick}
            {...props}
            className={`inline-flex items-center justify-center px-4 py-2 rounded-lg text-sm font-medium focus:outline-none
                    ${selected ? 'bg-primary text-white' : 'bg-gray-200 text-gray-800'}
                    ${!selected ? 'hover:bg-gray-300' : 'hover:bg-primary-dark'}
                `}
        >
            <span>{label}</span>
        </button>
    )
})

Checkbox.displayName = 'Checkbox'

export default Checkbox
