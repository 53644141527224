import { Outlet, useNavigate } from 'react-router-dom'
import { createContext, useEffect, useState } from 'react'
import { User } from '@supabase/supabase-js'
import Navbar from '../../components/common/navbar/navbar'
import { Divider } from '@mui/material'
import SideNavBar from '../../components/common/sidebar/sidebar'
import supabase from '../../utils/supabase'
import { EnvContextProvider } from '../../context/env_context'
import { EnvAPI } from '@/api/req/env_api'
import { Toaster } from 'react-hot-toast'
import { ChatBotWindow, FloatingChatButton } from '@/@ui-lib/molecules'
import { RiChat1Fill } from 'react-icons/ri'

interface HomePageContextType {
    user: User | null
}

export const HomePageContext = createContext<HomePageContextType>({
    user: null, // Default value
})

const HomePage: React.FC = () => {
    const [user, setUser] = useState<User | null>(null)
    const navigate = useNavigate()

    useEffect(() => {
        async function checkLogin() {
            const { data, error } = await supabase.auth.getUser()
            console.log(data.user)

            if (error) {
                navigate('/login')
            } else {
                EnvAPI.getAll().then(() => {
                    setUser(data?.user)
                })
            }
        }

        checkLogin()
    }, [])

    return (
        <div className="h-screen w-full bg-white flex">
            <HomePageContext.Provider value={{ user }}>
                <div className="h-full max-w-64 z-50 bg-white border-r shadow-md">
                    <SideNavBar />
                </div>
            </HomePageContext.Provider>

            <EnvContextProvider>
                <div className="flex-grow flex flex-col">
                    {/* <HomePageContext.Provider value={{ user }}>
                        <Navbar />
                    </HomePageContext.Provider>
                    <Divider /> */}
                    <div className="flex-grow overflow-y-auto">
                        <Outlet />
                    </div>
                </div>
                <Toaster position="top-right" />
            </EnvContextProvider>
        </div>
    )
}

export default HomePage
