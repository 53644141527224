import React from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts'

interface BarChartProps {
    results: { window_size: string; value: number }[]
    event_name: string
}

const BarChart: React.FC<BarChartProps> = ({ results, event_name }: BarChartProps) => {
    return (
        <LineChart width={1000} height={300} data={results}>
            <Line type="monotone" dataKey="value" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="window_size" />
            <YAxis />
            <Tooltip />
        </LineChart>
    )
}

export default BarChart
