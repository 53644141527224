import { Button } from '@/@ui-lib/atoms'
import { Trash2 } from 'lucide-react'
import React, { useEffect, useState } from 'react'

export interface Group {
    key: string
    values: string[]
}

interface MeterGroupProps {
    required?: boolean
    onDataChange?: (data: Group[]) => void
    meterGroups: Group[]
    setMetermeterGroups: React.Dispatch<React.SetStateAction<Group[]>>
}

const MeterGroup: React.FC<MeterGroupProps> = ({
    required = false,
    onDataChange,
    meterGroups,
    setMetermeterGroups,
}) => {
    useEffect(() => {
        onDataChange?.(meterGroups)
    }, [meterGroups, onDataChange])

    // Add a new group automatically when needed
    const ensureGroupExists = () => {
        // const lastGroup = meterGroups[meterGroups.length - 1]
        // if (lastGroup.key.trim() || lastGroup.values.length > 0) {
        //     setMetermeterGroups((prev) => [...prev, { key: '', values: [] }])
        // }
    }

    const addGroup = () => {
        setMetermeterGroups((prev) => [...prev, { key: '', values: [] }])
    }

    // Delete a specific group
    const handleDeleteGroup = (index: number) => {
        const updatedmeterGroups = meterGroups.filter((_, i) => i !== index)
        setMetermeterGroups(updatedmeterGroups)
    }

    // Update a specific group's key
    const handleKeyChange = (index: number, value: string) => {
        const updatedmeterGroups = [...meterGroups]
        updatedmeterGroups[index].key = value
        setMetermeterGroups(updatedmeterGroups)
        ensureGroupExists()
    }

    // Add a chip to a group's values
    const handleAddValue = (index: number, value: string) => {
        if (!value.trim()) return
        const updatedmeterGroups = [...meterGroups]
        updatedmeterGroups[index].values.push(value.trim())
        setMetermeterGroups(updatedmeterGroups)
        ensureGroupExists()
    }

    // Remove a chip from a group's values
    const handleRemoveValue = (groupIndex: number, value: string) => {
        const updatedmeterGroups = [...meterGroups]
        updatedmeterGroups[groupIndex].values = updatedmeterGroups[groupIndex].values.filter((v) => v !== value)
        setMetermeterGroups(updatedmeterGroups)
    }

    return (
        <div>
            <label htmlFor="" className="w-full block text-base font-semibold text-[##1E1E1E] mb-2">
                Meter Groups
                {required && <span className="text-[#1E1E1E] ml-1">*</span>}
            </label>

            <div className="space-y-4 w-full">
                {meterGroups.map((group, index) => (
                    <div key={index} className="w-full flex justify-normal items-center gap-2 mb-2">
                        <div className="border w-full p-4 rounded-md flex flex-col gap-4 relative">
                            {/* Key Input */}
                            <div className="w-full flex gap-2 items-center">
                                <p className="font-semibold mr-4">{'Key'}</p>
                                <input
                                    type="text"
                                    placeholder="model"
                                    value={group.key}
                                    onChange={(e) => handleKeyChange(index, e.target.value)}
                                    className="flex-grow w-full border p-1 rounded border-gray-300 outline-none focus:border-primary"
                                />
                            </div>

                            {/* Chips and Input */}
                            <div className="w-full flex gap-2 items-center">
                                <p className="font-semibold">Values</p>
                                <div className="border rounded p-2 w-full">
                                    {/* Chips */}
                                    <div className="flex flex-wrap gap-2 mb-2">
                                        {group.values.map((value, i) => (
                                            <span
                                                key={i}
                                                className="flex items-center px-3 py-1 bg-gray-200 rounded-full"
                                            >
                                                {value}
                                                <button
                                                    className="ml-2 text-red-500"
                                                    onClick={() => handleRemoveValue(index, value)}
                                                >
                                                    &times;
                                                </button>
                                            </span>
                                        ))}
                                    </div>

                                    {/* Real-Time Input */}
                                    <input
                                        type="text"
                                        placeholder={group.values.length > 0 ? "":"llma"}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleAddValue(index, e.currentTarget.value)
                                                e.currentTarget.value = '' // Clear input
                                                e.preventDefault()
                                            }
                                        }}
                                        className="flex-grow w-full border-gray-300 outline-none focus:border-primary"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Delete Group Button */}
                        <button onClick={() => handleDeleteGroup(index)} className="text-red-500 hover:text-red-700">
                            <Trash2 className="text-[0.35rem]" />
                        </button>
                    </div>
                ))}
            </div>

            <div className="mt-4">
                <Button buttonType={'outlined'} label="+ Add group by" onClick={addGroup} fullWidth />
            </div>
            {/* Instruction */}
            <span className="text-[#8A8A8A] text-[12px] block mt-[6px]">
                Name of the group by and the property key in the data object. The meterGroups should only include low
                cardinality fields. For example: AI model name, API endpoints without variable parameters.
            </span>
        </div>
    )
}

export default MeterGroup
