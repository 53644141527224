import { Button } from '@/@ui-lib/atoms'
import Spacer from '@/components/common/util/spacer'
import { DeleteForever } from '@mui/icons-material'
import { Input } from '@/@ui-lib/atoms'
export type Tier = {
    from: number | string
    to: number | string
    price: number
    unit: number
}

type TierRowProps = {
    tiers: Tier[]
    billing_period?:string,
    handleTierChange: (index: number, field: keyof Tier, value: string | number) => void
    removeTier: (index: number) => void
    addTier: () => void
}

const TierTable: React.FC<TierRowProps> = ({ tiers, handleTierChange, removeTier, addTier,billing_period="Monthly" }) => {
    return (
        <div className="w-500">
            <table className="table-auto border-collapse border border-gray-300 w-full text-left">
                <thead>
                    <tr>
                        <th className="border border-gray-300 px-4 py-2">From (units)</th>
                        <th className="border border-gray-300 px-4 py-2">To (units)</th>
                        <th className="border border-gray-300 px-4 py-2">{`Per unit (${billing_period})`}</th>
                        <th className="border border-gray-300 px-4 py-2">{`Flat fee (${billing_period})`}</th>
                        <th className="border border-gray-300 px-4 py-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tiers.map((tier, index) => (
                        <tr key={index}>
                            <td className="border border-gray-300 px-4 py-2">
                                <Input
                                    placeholder="From"
                                    field={{ value: tier.from.toString() }}
                                    name="from"
                                    label=""
                                    disabled={true}
                                    type="number"
                                />
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                                <Input
                                    placeholder="To"
                                    field={{
                                        value: tier.to.toString(),
                                        onChange: (e: any) => {
                                            if (tier.to !== '&above') {
                                                handleTierChange(index, 'to', e.target.value)
                                            }
                                        },
                                    }}
                                    name="to"
                                    label=""
                                    type={tier.to !== '&above' ? 'number' : 'text'}
                                />
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                                <Input
                                    placeholder="Unit"
                                    field={{
                                        value: tier.unit.toString(),
                                        onChange: (e: any) => handleTierChange(index, 'unit', e.target.value),
                                    }}
                                    name="unit"
                                    label=""
                                    type="number"
                                />
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                                <Input
                                    placeholder="Price"
                                    field={{
                                        value: tier.price.toString(),
                                        onChange: (e: any) => handleTierChange(index, 'price', e.target.value),
                                    }}
                                    name="price"
                                    label=""
                                    type="number"
                                />
                            </td>
                            <td className="border border-gray-300 px-4 py-2">
                                {index !== 0 && (
                                    <button onClick={() => removeTier(index)} className="text-blue-600">
                                        <DeleteForever />
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Spacer height={10} />
            <div className="flex justify-end">
                <Button label="Add Tier" onClick={addTier} />
            </div>
        </div>
    )
}

export default TierTable
