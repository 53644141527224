import { Button, Checkbox, FlexSelect, Input } from '@/@ui-lib/atoms'
import { useEffect, useState } from 'react'
import PriceCreation, { PricingForm } from './widgets/PriceCreation'
import SelectableGroup from '@/@ui-lib/molecules/RadioSelect'
import { MeterAPI } from '@/api/req/meter_api'
import { convertModelMeterToSuper } from '@/utils/utility_functions'
import { PlanAPI } from '@/api/req/plan_api'
import { useNavigate } from 'react-router-dom'

const formStructure = {
    plan_name: '',
    plan_description: '',
    billing_period: 'monthly',
    plan_currency: 'USD',
    billing_time: 'arrear',
    pricing: [] as PricingForm[],
}

const PlanCreatePage = () => {
    const [planCreateForm, setPlanCreateForm] = useState(formStructure)

    const [submiting, setSubmiting] = useState<boolean>(false)

    const navigate = useNavigate()

    const handleChange = (field: string, value: any) => {
        setPlanCreateForm((prev) => ({
            ...prev,
            [field]: value,
        }))
    }

    const [meters, setMeters] = useState<any>()

    useEffect(() => {
        MeterAPI.getAll().then((response) => {
            const meters = convertModelMeterToSuper(response ?? [])
            setMeters(meters)
        })
    }, [])

    const addPrice = (price: any) => {
        setPlanCreateForm((prevForm: any) => ({
            ...prevForm,
            pricing: [...prevForm.pricing, price],
        }))
    }

    const removePrice = (index: number) => {
        setPlanCreateForm((prevForm: any) => ({
            ...prevForm,
            pricing: prevForm.pricing.filter((_: any, i: number) => i !== index),
        }))
    }

    const options = [
        {
            value: 'arrear',
            label: 'At the end of each billing period (in arrears)',
        },
        {
            value: 'advance',
            label: 'Immediately at the event reception (in advance)',
        },
    ]
    const createPlan = () => {
        setSubmiting(true);

        // Prepare the payload for the API
        const data = {
            name: planCreateForm.plan_name,
            description: planCreateForm.plan_description,
            currency: planCreateForm.plan_currency,
            prices: planCreateForm.pricing.flatMap((price) => getPrice(price)),
            invoice_cadence: planCreateForm.billing_time.toUpperCase()
        };
        console.log(data);
        PlanAPI.createPlanNew(false, data)
            .then((response) => {
                console.log('Plan created successfully:', response);
                setSubmiting(false);
                navigate(-1)
            })
            .catch((error) => {
                console.error('Error creating plan:', error);
                setSubmiting(false); // Reset the loading state
            });
        
    };

    const getPrice = (price: PricingForm) => {

        const default_tiers = planCreateForm.billing_period === 'monthly' ? price.default_monthly_price_tiered : price.default_annual_price_tiered;
        const default_price = planCreateForm.billing_period === 'monthly' ? price.default_monthly_price : price.default_annual_price;
        const default_price_units = planCreateForm.billing_period === 'monthly' ? price.default_monthly_price_units : price.default_annual_price_units;
        const tiers = planCreateForm.billing_period === 'monthly' ? price.monthly_price_tiered : price.annual_price_tiered;
        const pricing = planCreateForm.billing_period === 'monthly' ? price.monthly_price : price.annual_price;
        const pricing_units = planCreateForm.billing_period === 'monthly' ? price.monthly_price_units : price.annual_price_units;

        const filters = price.filter

        console.log(price)
        const defaultPrice = {
            billing_cadence: "RECURRING",
            amount: parseFloat((default_price??0).toString()),
            billing_model: price.billing_model,
            billing_period: planCreateForm.billing_period === "monthly" ? "MONTHLY":"ANNUAL",
            billing_period_count: 1,
            currency: planCreateForm.plan_currency,
            tier_mode: "VOLUME",
            meter_id: price.billable_metric,
            type: price.pricing_type === 'usage' ? "USAGE":"FIXED",
            tiers: (default_tiers).map((tier) => {
                return {
                    flat_amount:parseFloat(tier.price.toString()),
                    unit_amount: parseFloat(tier.unit.toString()),
                    up_to: tier.to === "&above"? null :Number.parseInt(tier.to.toString())
                }
            }),
            transform:{
                divide_by:Number.parseInt(default_price_units.toString()),
                round: "string"
            }, 
        }
        let filter_value: any = {};
        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i].split(':');
            const [key, value] = filter;
            if(!filter_value[key])
                filter_value[key] = [value]
            else
                filter_value[key] = [...filter_value[key],value];
        }

        const priceData = {
            billing_cadence: "RECURRING",
            amount: parseFloat((pricing??0).toString()),
            billing_model: price.billing_model,
            billing_period: planCreateForm.billing_period === "monthly"?"MONTHLY":"ANNUAL",
            billing_period_count: 1,
            currency: planCreateForm.plan_currency,
            tier_mode: "VOLUME",
            meter_id: price.billable_metric,
            type: price.pricing_type === 'usage' ? "USAGE":"FIXED",
            tiers: (tiers).map((tier) => {
                return {
                    flat_amount:parseFloat(tier.price.toString()),
                    unit_amount: parseFloat(tier.unit.toString()),
                    up_to: tier.to === "&above"? null : Number.parseInt(tier.to.toString())
                }
            }),
            filter_values: filter_value,
            transform:{
                divide_by:Number.parseInt(pricing_units.toString()),
                round: "string"
            }
        }
        let result = []
        if(price.pricing_type === 'recurring')
            result =  [priceData]
        else if (price.filter.length === 0)
            result =  [defaultPrice]
        else
            result = [defaultPrice,priceData]

        return result
    }
    return (
        <div className="max-w-4xl mx-auto p-4">
            <div className="w-full text-center py-4 bg-white shadow-sm">
                <div className='flex items-start'><h1 className="text-2xl font-bold">Create Plan</h1></div>
                <div className="border-t mt-2 border-gray-300"></div>
            </div>
            <div className="border border-gray-300 rounded-md p-4 mb-6">
                <h2 className="text-xl font-semibold mb-4">Plan Details</h2>
                <div className="grid grid-cols-1 gap-4">
                    <Input
                        label="Plan Name *"
                        field={{
                            value: planCreateForm.plan_name,
                            onChange: (e: any) => handleChange('plan_name', e.target.value),
                        }}
                        placeholder="Enter plan name"
                        name="plan_name"
                    />
                    <Input
                        label="Plan Description"
                        field={{
                            value: planCreateForm.plan_description,
                            onChange: (e: any) => handleChange('plan_description', e.target.value),
                        }}
                        placeholder="Enter plan description"
                        name="plan_description"
                    />
                </div>
            </div>

            <div className="border border-gray-300 rounded-md p-4 mb-6">
                <h2 className="text-xl font-semibold mb-4">Billing Details</h2>
                <div className="flex items-center gap-4 mb-4">
                    <Checkbox
                        label="Monthly"
                        selected={planCreateForm.billing_period === 'monthly'}
                        onClick={() =>
                            handleChange('billing_period', 'monthly')
                        }
                    />
                    <Checkbox
                        label="Annually"
                        selected={planCreateForm.billing_period === 'annually'}
                        onClick={() => {
                            handleChange(
                                'billing_period',
                                'annually'
                            )
                        }}
                    />
                </div>
                <div>
                    <FlexSelect
                        label="Select currency"
                        name="currency"
                        field={{
                            value: planCreateForm.plan_currency,
                            onChange: (value: any) => {
                                handleChange('plan_currency', value)
                            },
                        }}
                        options={['USD', 'INR'].map((key) => ({
                            value: key,
                            id: key,
                        }))}
                    />
                    <div className="mt-3" />

                    <SelectableGroup
                        name="Billing timing"
                        options={options}
                        multiple={false}
                        selectedValue={planCreateForm.billing_time}
                        onChange={(newVal) => handleChange('billing_time', newVal)}
                    />
                </div>
            </div>

            <div className="border border-gray-300 rounded-md p-4">
                <h2 className="text-xl font-semibold mb-4">Set-up Pricing</h2>
                <div className="grid gap-4">
                    {planCreateForm.pricing.map((pricing: any, index) => (
                        <div
                            key={index}
                            className="border border-gray-200 rounded-md p-4 shadow-md flex justify-between items-center bg-gray-50"
                        >
                            <div>
                                <span className="font-medium text-lg">
                                    {pricing.pricing_type}-based
                                </span>
                                <p className="text-sm text-gray-600">{meters[pricing.billable_metric]?.event_name}
                                </p>
                            </div>
                            <button
                                className="text-red-500 font-medium hover:underline"
                                onClick={() => removePrice(index)}
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                </div>
                <div className="mt-4">
                    <PriceCreation
                        meters={meters}
                        onSubmit={(data) => {
                            addPrice(data)
                        }}
                        billing_annually={planCreateForm.billing_period === 'annually'}
                        billing_monthly={planCreateForm.billing_period === 'monthly'}
                    />
                </div>

            </div>
            <div className="mt-3">
                <Button
                    label="Create plan"
                    type="submit"
                    fullWidth
                    isLoading={submiting}
                    disabled={submiting}
                    onClick={createPlan}
                />
            </div>
        </div>
    )
}

export default PlanCreatePage
