import { FlexTable } from "@/@ui-lib/molecules"
import { ModelCustomer } from "@/api/models/model_customer"
import { CustomerAPI } from "@/api/req/customer_api"
import { SubscriptionAPI } from "@/api/req/subscription_api"
import { formatNumber, transformFiltersToArray } from "@/utils/utility_functions"
import { Container } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import CustomerDetail from "./widgets/CustomerDetail"


const CustomerSubscriptionUsage = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const subscriptionId = searchParams.get("subscription_id")
    const customerId = searchParams.get('id')
    const [customerDetails, setCustomerDetails] = useState<ModelCustomer>()


    const [tableData,setTableData] = useState<any>([])

    useEffect(() => {
        if (customerId) {
            fetchCustomerDetails(customerId)
        }
    }, [])

    useEffect(() => {
        if (subscriptionId) {
            fetchSubscriptions(subscriptionId)
        }
    }, [])



    const fetchCustomerDetails = (customerId: string) => {
        CustomerAPI.getCustomerById(false, customerId).then((response) => {
            if (response) {
                setCustomerDetails(response)
            }
        })
    }

    const fetchSubscriptions = (subscriptionId: string) => {
        SubscriptionAPI.getSubscriptionUsage(false, subscriptionId).then((subscription:any)=>{

            if(subscription){
                const subscription_charges = subscription.charges ?? [];
                const result = (subscription_charges as []).map((item:any)=>{
                    return {
                        meter_display_name: item.meter_display_name?? '',
                        quantity: formatNumber(item.quantity),
                        display_amount: formatNumber(item.display_amount),
                        // startDate:formatDate(item.start_time, 'MMMM dd, yyyy'),
                        filters: transformFiltersToArray(item.filter_values ?? {})
                    }
                })
                setTableData(result)
            }
        })
    }

    return (
        <Container>
            <div className="h-8"></div>
            <div className="w-full flex justify-between items-center">
                <h1 className="font-semibold text-[24px] bg-white ">Subscription Detail</h1>
            </div>
            <div className="w-full border mt-2 mb-5"></div>

            {customerDetails && <CustomerDetail customer={customerDetails!} />}
            <div className="h-10 my-5"></div>

            {tableData && tableData.length > 0 && (
                    <FlexTable
                        columns={[{ key: 'meter_display_name', header: 'Event' },
                            {key:'filters',header:'Filter'},
                            { key: 'quantity', header: 'Units' },
                            { key: 'display_amount', header: 'Amount' }
                        ]}
                        data={tableData}
                    />
                )}
        </Container>
    )
}


export default CustomerSubscriptionUsage;