import React, { FC, InputHTMLAttributes } from 'react'

interface RadioProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    label: string
    name: string
    value: string
    checked: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
}

const Radio: FC<RadioProps> = ({ label, name, value, checked, onChange, disabled = false, ...rest }) => {
    return (
        <label
            className={`inline-flex items-center space-x-2 cursor-pointer ${
                disabled ? 'cursor-not-allowed opacity-60' : ''
            }`}
        >
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                className="h-4 w-4 text-primary border-gray-300 focus:ring-primary"
                {...rest}
            />
            <span className="text-sm text-gray-700">{label}</span>
        </label>
    )
}

interface Option {
    label: string
    value: string
    disabled?: boolean
}

interface SelectableGroupProps {
    name: string
    options: Option[]
    multiple?: boolean
    selectedValue?: string
    selectedValues?: string[]
    onChange: (newValue: string | string[]) => void
}

const SelectableGroup: React.FC<SelectableGroupProps> = ({
    name,
    options,
    multiple = false,
    selectedValue,
    selectedValues = [],
    onChange,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        if (multiple) {
            let newValues
            if (selectedValues.includes(value)) {
                newValues = selectedValues.filter((v) => v !== value)
            } else {
                newValues = [...selectedValues, value]
            }
            onChange(newValues)
        } else {
            onChange(value)
        }
    }

    return (
        <div className="flex flex-col space-y-2">
            {options.map((option) => (
                <Radio
                    key={option.value}
                    name={name}
                    label={option.label}
                    value={option.value}
                    checked={selectedValue === option.value}
                    onChange={handleChange}
                    disabled={option.disabled}
                />
            ))}
        </div>
    )
}

export default SelectableGroup
