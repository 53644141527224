import { forwardRef } from 'react'

interface Column {
    key: string
    header: string
}

interface Data {
    [key: string]: string | number | string[] // Include `string[]` for filter arrays
}

interface TableProps {
    columns: Column[]
    data: Data[]
    onRowClick?: (row: Data) => void
}

const FlexTable = forwardRef<HTMLDivElement, TableProps>(({ columns, data, onRowClick }: TableProps, ref) => {
    return (
        <div ref={ref} className="">
            <div className="border border-gray-300 rounded-lg p-2">
                <table className="table-auto w-full">
                    <thead className="border-b border-gray-300">
                        <tr>
                            {columns.map((column) => (
                                <th key={column.key} className="px-4 py-2 text-left text-primary">
                                    {column.header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr key={rowIndex} onClick={() => onRowClick && onRowClick(row)}>
                                {columns.map((column) => (
                                    <td
                                        key={column.key}
                                        className={`px-4 py-2 ${
                                            rowIndex !== data.length - 1 ? 'border-b border-gray-300' : ''
                                        } ${onRowClick ? 'cursor-pointer hover:bg-gray-50' : ''}`}
                                    >
                                        {column.key === 'filters' && Array.isArray(row[column.key]) && (row[column.key] as string[]).length > 0? (
                                            <div className="flex items-center relative">
                                                <span
                                                    className="px-2 py-1 bg-blue-100 text-blue-600 rounded-full text-sm text-center"
                                                >
                                                    {(row[column.key] as string[])[0]} {/* Display the first chip */}
                                                </span>
                                                {(row[column.key] as string[]).length > 1 && (
                                                    <span
                                                        className="ml-2 px-2 py-1 bg-gray-200 text-gray-700 rounded-full text-sm text-center relative group"
                                                    >
                                                        +{(row[column.key] as string[]).length - 1}
                                                        <div
                                                            className="absolute top-full mt-1 hidden group-hover:flex flex-col bg-white shadow-lg border border-gray-300 rounded-md p-2 z-50"
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: '200px',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            {(row[column.key] as string[])
                                                                .slice(1) // Display remaining chips
                                                                .map((remaining, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-sm mb-1 last:mb-0"
                                                                    >
                                                                        {remaining}
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            row[column.key]
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
})

FlexTable.displayName = 'FlexTable'

export default FlexTable
